import {NavigationExtras} from '@angular/router';
import {createAction, props} from '@ngrx/store';

const RouterActionTypes = {
  GO: '[Router] Go',
  BACK: '[Router] Back',
  FORWARD: '[Router] Forward',
  REDIRECT: '[Router] Redirect',
} as const;
type RouterActionTypes =
  (typeof RouterActionTypes)[keyof typeof RouterActionTypes];

export const Go = createAction(
  RouterActionTypes.GO,
  props<{
    payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
    };
  }>()
);

export const Back = createAction(RouterActionTypes.BACK);

export const Forward = createAction(RouterActionTypes.FORWARD);

export const Redirect = createAction(
  RouterActionTypes.REDIRECT,
  props<{url: string}>()
);
